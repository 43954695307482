<template>
	<div class="container">
		<div class="lang">
			<el-select v-model="value" placeholder="请选择" @change="clickChange">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
			</el-select>
		</div>
		
		<h2>“Whistle”用户服务协议</h2>

		<p>欢迎您使用“Whistle”应用软件及相关服务！<br>
			为了更好地为您提供服务，请您仔细阅读《“Whistle”用户服务协议》（以下简称“本协议”）。在您开始使用“Whistle”软件及相关服务之前，请您务必认真阅读并充分理解本协议，特别是涉及免除或者限制责任的条款、权利许可和信息使用的条款、同意开通和使用特殊单项服务的条款、法律适用和争议解决条款等。其中，免除或者限制责任条款等重要内容将以加粗形式提示您注意，您应重点阅读。如您未满18周岁，请您在法定监护人陪同下仔细阅读并充分理解本协议，并征得法定监护人的同意后使用“Whistle”软件及相关服务。<br>
			除非您完全接受本协议的全部内容，否则您无权下载、安装、注册、登录、使用（以下统称“使用”）“Whistle”软件，或者通过任何方式使用“Whistle”服务，或者获得“Whistle”软件提供的任何服务
			（本协议中统称“使用”）。若您使用“Whistle”软件及相关服务，则视为您已充分理解本协议并承诺作为本协议的一方当事人接受协议的约束。<br>
			如对本协议内容有任何疑问、意见或建议，您可通过登录“Whistle”客户端内的“反馈与帮助”页面与我们联系。</p>

		<h3>1、适用范围</h3>
		<p>1.1本协议是您与CLICK ADVERTISING & MEDIA PTE.
			LTD.（以下简称“公司”或“我们”）之间就您下载、安装、注册、登录、使用“Whistle”软件，并获得“Whistle”软件提供的相关服务所订立的协议。公司有权依“Whistle”软件及相关服务或运营的需要单方决定，安排或指定其关联公司、控制公司、继承公司或公司认可的第三方公司继续运营“Whistle”软件。并且，就本协议项下涉及的某些服务，可能会由公司的关联公司、控制公司、继承公司或公司认可的第三方公司向您提供。您知晓并同意接受相关服务内容，即视为接受相关权利义务关系亦受本协议约束。<br>
			1.2“用户”指所有直接或间接获取和使用“Whistle”软件及相关服务的使用者，包括自然人、法人和其他组织等。在本协议中称为“用户”或称“您”。
			1.3“Whistle”指由公司合法拥有并运营的、标注名称为“Whistle”的客户端应用程序以及公司的网站。公司有权对应用程序及网站名称单方变更（包括但不限于更名、新增等）。
			1.4本协议内容同时包括公司已经发布及后续可能不断发布的关于“Whistle”软件及相关服务的相关协议、规则等内容（包括《“Whistle”隐私政策》）。前述内容一经正式发布，公司将以适当的方式（网站公布、系统通知等）提醒您及时阅读。前述相关协议、规则构成本协议不可分割的组成部分，您应同样遵守。
		</p>

		<h3>2、使用“Whistle”软件及相关服务</h3>
		<p>
			2.1您使用“Whistle”软件及相关服务，可以通过预装、公司已授权的第三方下载等方式获取“Whistle”客户端应用程序或访问Whistle相关网站。若您并非从公司或经公司授权的第三方获取本软件的，公司无法保证非官方版本的“Whistle”软件能够正常使用，您因此遭受的损失与公司无关。<br>
			2.2公司可能为不同的终端设备开发了不同的应用程序软件版本，您应当根据实际设备状况获取、下载、安装合适的版本。<br>
			2.3您可根据自行需要使用“Whistle”软件及相关服务或更新“Whistle”版本，如您不再需要使用“Whistle”软件及相关服务可自行卸载。<br>
			2.4为更好的提升用户体验及服务，公司将不定期提供软件更新或改变（包括但不限于软件修改、升级、功能强化、开发新服务、软件替换等）。为保证“Whistle”软件及相关服务安全、提升用户服务，本软件及相关服务更新或部分服务内容更新后，在可能的情况下，公司将以包括但不限于系统提示、公告、站内信等方式提示用户，用户有权选择接受更新版本或服务，如用户不接受，部分功能将受到限制或不能继续使用。<br>
			2.5除非得到公司明示事先书面授权，您不得以任何形式对“Whistle”软件及相关服务进行包括但不限于改编、复制、传播、垂直搜索、镜像或交易等未经授权的访问或使用。<br>
			2.6您理解，您使用“Whistle”软件及相关服务需自行准备与软件及相关服务有关的终端设备（如电脑、手机等装置），一旦您在其终端设备中打开“Whistle”软件或访问Whistle相关网站，即视为您使用“Whistle”软件及相关服务。为充分实现“Whistle”的全部功能，您可能需要将其终端设备联网，您理解由您承担所需要的费用（如流量费、上网费等）。<br>
			2.7公司许可您个人的、可撤销的、不可转让的、非独占地和非商业的合法使用“Whistle”软件及相关服务的权利。本协议未明示授权的其他一切权利仍由公司保留，您在行使该些权利时须另行获得公司的书面许可，同时公司如未行使前述任何权利，并不构成对该权利的放弃。<br>
			2.8您无需注册即可开始使用“Whistle”软件及相关服务。同时，您也理解，为使您更好地使用“Whistle”软件及相关服务，保障您的账号安全，某些功能和/或某些单项服务项目，如跟帖评论服务等，要求您按照国家相关法律法规的规定，提供真实的身份信息实名注册并登陆后方可使用。<br>
			2.9如您发现“Whistle”软件及网站内存在任何侵犯您权利的内容，请立即按照Whistle侵权投诉窗口规定的方式通知公司，提供您有相关权利的初步证据，公司将会根据当地法律规定及时处理您的投诉。如您在“Whistle”发布的内容被相关权利人投诉侵权，您也可以通过向客户端提交反通知书及相关权利证明材料，来向公司进行申诉，公司同样将会根据当地法律规定及时处理您的申诉。
		</p>

		<h3>3、关于“账号”</h3>

		<p>3.1 “Whistle”为用户提供了注册通道，用户有权选择合法的字符组合作为自己的账号，并自行设置符合安全要求的密码。用户设置的账号、密码是用户用以登录并以注册用户身份使用“Whistle”软件及相关服务的凭证。</p>
		<p>3.2 <strong>账号注销</strong><br>
			在需要终止使用“Whistle”账号服务时，符合以下条件的，您可以申请注销您的“Whistle”账号：<br>
			（1）您仅能申请注销您本人的账号，并依照“Whistle”的流程进行注销；<br>
			（2）您仍应对您在注销账号前且使用“Whistle”服务期间的行为承担相应责任；<br>
			（3）注销成功后，账号记录、功能等将无法恢复或提供。<br>
			如您需要注销您的“Whistle”账号，请打开Whistle客户端按提示进行注销。</p>
		<p>3.3 您理解并承诺，您所设置的账号不得违反国家法律法规及公司的相关规则，您的账号名称、头像和简介等注册信息及其他个人信息中不得出现违法和不良信息，未经他人许可不得用他人名义（包括但不限于冒用他人姓名、名称、字号、头像等足以让人引起混淆的方式）开设账号，不得恶意注册“Whistle”账号（包括但不限于频繁注册、批量注册账号等行为）。您在账号注册及使用过程中需遵守相关法律法规，不得实施任何侵害国家利益、损害其他公民合法权益，有害社会道德风尚的行为。公司有权对您提交的注册信息进行审核。</p>
		<p>3.4 您在“Whistle”中的注册账号仅限于您本人使用，未经公司书面同意，禁止以任何形式赠与、借用、出租、转让、售卖或以其他方式许可他人使用该账号。如果公司发现或者有合理理由认为使用者并非账号初始注册人，为保障账号安全，公司有权立即暂停或终止向该注册账号提供服务，或注销该账号。</p>
		<p>3.5您有责任维护个人账号、密码的安全性与保密性，并对您以注册账号名义所从事的活动承担全部法律责任，包括但不限于您在“Whistle”上进行的任何数据修改、言论发表、款项支付等操作行为。您应高度重视对账号与密码的保密，在任何情况下不向他人透露账号及密码。若发现他人未经许可使用您的账号或发生其他任何安全漏洞问题时，您应当立即通知公司。</p>
		<p>3.6您的账号在丢失或遗忘密码后，可遵照公司的申诉途径及时申诉请求找回账号或密码。您理解并认可，公司的密码找回机制仅需要识别申诉单上所填资料与系统记录资料具有一致性，而无法识别申诉人是否系真正账号有权使用者。公司特别提醒您应妥善保管您的账号和密码。当您使用完毕后，应安全退出。因您保管不当等自身原因或其他不可抗因素可能导致遭受盗号或密码丢失，责任由您自行承担。</p>
		<p>3.7在注册、使用和管理账号时，您应保证注册账号时填写的身份信息的真实性，请您在注册、管理账号时使用真实、准确、合法、有效的相关身份证明材料及必要信息（包括但不限于您的姓名及电子邮件地址、联系电话、联系地址等）。依照国家法律法规的规定，为使用本软件及服务的部分功能，您需要填写真实的身份信息，请您按照相关法律规定完成实名认证，并注意及时更新上述相关信息。若您提交的材料或提供的信息不准确、不真实、不规范、不合法或者公司有理由怀疑为错误、不实或不合法的资料，则公司有权拒绝为您提供相关服务或您可能无法使用“Whistle”软件及相关服务或在使用过程中部分功能受到限制。</p>
		<p>3.8除自行注册“Whistle”账号外，您也可选择通过授权使用您合法拥有的包括但不限于公司和/或其关联公司其他软件用户账号，以及实名注册的第三方软件或平台用户账号注册并登录使用“Whistle”软件及相关服务，但第三方软件或平台对此有限制或禁止的除外。当您以前述已有账号登录使用的，应保证相应账号已进行实名注册登记，并同意适用本协议中的相关约定。</p>
		<p>3.9您理解并同意，除您登录、使用“Whistle”软件及相关服务外，您还可以用“Whistle”账号登录使用公司及其关联公司或其他合作方提供的其他软件、服务。您以“Whistle”账号登陆并使用前述服务的，同样应受其他软件、服务提供方的《用户协议》及其他协议条款约束。</p>
		<p>3.10为提高您内容曝光量及发布效率，您同意您在本软件/网站的账号及相应账号所发布的全部内容均授权本公司以您的账号自动同步发布至公司及/或关联公司运营的系列客户端软件及网站。您在本软件/网站发布、修改、删除内容的操作，均会同步到上述系列客户端软件及网站。<br>您通过已注册或者已同步的账号登录公司及/或关联公司运营的系列客户端软件产品及网站时（如有），应遵守该软件产品及网站自身的《用户协议》及其他协议条款的规定。</p>
		<p>3.11因其他人恶意攻击或您自身原因或其他不可抗因素而导致账号被盗、丢失，均由您本人承担责任，公司不承担任何责任。</p>
		<p>3.12您理解，为了充分使用账号资源，如您在注册后未及时进行初次登录使用或连续超过二个月未登录闲置账号的使用等情形，公司有权随时收回您的账号。如您的账号被收回，你可能无法通过您此前持有的账号登录并使用“Whistle”软件及相关服务，您该账号下保存的任何个性化设置和使用记录将无法恢复。</p>
		<p>在收回您的账号之前，公司将以适当的方式向您作出提示，如您在收到相关提示后一定期限内仍未登录、使用账号，公司将进行账号收回。</p>
		<p>如您的账号被收回，您可以通过注册新的账号登录、使用“Whistle”软件及相关服务。您注册新账号并登录、使用的行为仍受到本协议相关条款的约束。</p>

		<h3>4、用户个人信息保护</h3>

		<p>4.1 我们非常重视用户信息的保护，保护用户个人信息是公司的基本原则之一。公司与用户一同致力于用户个人信息（即能够独立或与其他信息结合后识别用户身份的信息）的保护。</p>
		<p>4.2
			在使用“Whistle”软件及相关服务的过程中，您可能需要提供您的个人信息（包括但不限于您的姓名、电话号码、位置信息、设备信息等），以便公司向您提供更好的服务和相应的技术支持。您理解并同意，公司有权在遵守法律法规、本协议以及《“Whistle”隐私政策》的前提下，收集、使用（含商业合作使用）、存储和分享您的个人信息，同时，我们将依法保护您浏览、修改、删除相关个人信息以及撤回同意的权利。
		</p>
		<p>4.3 您可随时浏览、修改自己提交的个人身份信息，您理解并同意出于安全性和身份识别（如账号或密码找回申诉服务等）的考虑，您可能无法修改注册时提供的初始注册信息及其他验证信息。</p>
		<p>4.4 公司将运用与“Whistle”软件及相关服务相匹配的安全技术及其他安全措施，并建立完善的管理制度来保护您的个人信息，防止您的信息被不当使用或被未经授权的访问、使用或泄漏。</p>
		<p>4.5 未经您的同意，公司不会向公司以外的任何公司、组织和个人披露您的个人信息，下列情形除外：</p>
		<p>
			（1）事先获得您的明确授权同意；<br>
			（2）您自行向其他公司、组织和个人共享、转让、公开的；<br>
			（3）与国家安全、国防安全、公共安全、公共卫生、公共利益直接相关的；<br>
			（4）根据适用的法律法规的要求、强制性的行政和司法要求所必须的情况下进行披露或提供，或与犯罪侦查、起诉、审判和判决执行等直接相关的。在符合法律法规的前提下，当公司收到前述披露信息的请求时，公司会要求对方必须出具与之相应的法律文件，如传票或调查函。公司将对所有的请求都进行了审慎的审查，以确保其具备合法依据，且仅限于行政、司法部门因特定调查目的有合法权利获取的数据；<br>
			（5）在法律法规允许的范围内，为维护“Whistle”其他用户、公司及其关联公司、控制公司的生命、财产等合法权益或维权产品或服务的安全稳定运行所必需的，例如查找、预防、处理欺诈等违法活动和减少信用风险等（不包括违反本协议中所做的承诺而为获利目的对外公开或提供个人信息）；<br>
			（6）从合法公开披露的信息中个人信息的，如合法的新闻报道、政府信息公开等渠道；<br>
			（7）法律法规规定的其他情形。<br>
		</p>
		<p>4.6请您注意勿在使用“Whistle”软件及相关服务中透露自己的各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料，否则由此带来的任何损失由您自行承担。</p>
		<p>4.7 考虑到“Whistle”软件及相关服务中可能包括或链接至第三方提供的信息或其他服务（包括网站）。您知悉并理解，运营该等服务的第三方可能会要求您提供个人信息。公司特别提醒您，需要认真阅读该等第三方的用户协议、隐私政策以及其他相关的条款，妥善保护自己的个人信息，仅在必要的情况下向该等第三方提供。本协议（以及其他与“Whistle”软件及相关服务相关的协议和规则（包括但不限于《“Whistle”隐私政策》）不适用于任何第三方提供的服务，公司对任何因第三方使用由您提供的个人信息所可能引起的后果不承担任何法律责任。<br>更多关于用户个人信息保护的内容，请参看《“Whistle”隐私政策》。</p>
		
		<h2>5、用户行为规范</h2>
		
		<h3>5.1 用户行为要求</h3>
		<p>您应对其使用本产品及相关服务的行为负责，除非法律允许或者经公司事先书面许可，您使用“Whistle”软件及相关服务不得具有下列行为：</p>
	
		<p>5.1.1 使用未经公司授权或许可的任何插件、外挂、系统或第三方工具对“Whistle”软件及相关服务的正常运行进行干扰、破坏、修改或施加其他影响。</p>
	
		<p>5.1.2 利用或针对“Whistle”软件及相关服务进行任何危害计算机网络安全的行为，包括但不限于：</p>
		<ul>
			<li>(1) 非法侵入他人网络、干扰他人网络正常功能、窃取网络数据等危害网络安全的活动；</li>
			<li>(2) 提供专门用于从事侵入网络、干扰网络正常功能及防护措施、窃取网络数据等危害网络安全活动的程序、工具；</li>
			<li>(3) 明知他人从事危害网络安全的活动的，为其提供技术支持、广告推广、支付结算等帮助；</li>
			<li>(4) 使用未经许可的数据或进入未经许可的服务器/账号；</li>
			<li>(5) 未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；</li>
			<li>(6) 未经许可，企图探查、扫描、测试“Whistle”系统或网络的弱点或其它实施破坏网络安全的行为；</li>
			<li>(7) 企图干涉、破坏“Whistle”系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；</li>
			<li>(8) 伪造TCP/IP数据包名称或部分名称。</li>
		</ul>
	
		<p>5.1.3 对“Whistle”软件进行反向工程、反向汇编、编译或者以其他方式尝试发现本软件的源代码。</p>
	
		<p>5.1.4 恶意注册“Whistle”账号，包括但不限于频繁、批量注册账号。</p>
	
		<p>5.1.5 违反法律法规、本协议、公司的相关规则及侵犯他人合法权益的其他行为。</p>
	
		<p>在任何情况下，如果公司有理由认为您的任何行为违反或可能违反上述约定的，公司可独立进行判断并处理，且在任何时候有权在进行任何事先通知的情况下终止向用户提供服务，并追究相关责任。</p>
	
		<h3>5.2 信息内容规范</h3>
		<p>5.2.1 用户按规定完成实名认证后，可以以注册账号或“Whistle”合作平台账号登录“Whistle”发布内容、跟帖评论等。</p>
	
		<p>5.2.2 公司致力使跟帖评论成为文明、理性、友善、高质量的意见交流。在推动跟帖评论业务发展的同时，不断加强相应的信息安全管理能力，完善跟帖评论自律，切实履行社会责任，遵守国家法律法规，尊重公民合法权益，尊重社会公序良俗。</p>
	
		<p>5.2.3 用户制作、评论、发布、传播的内容（包括但不限于上传至“Whistle”平台的未公开的私密视频、发布后展示时效为72小时的随拍）应自觉遵守法律法规、社会主义制度、国家利益、公民合法权益、社会公共秩序、道德风尚和信息真实性等“七条底线”要求，否则公司将立即采取相应处理措施。用户不得发表下列信息：</p>
		<ul>
			<li>（1）反对宪法确定的基本原则的；</li>
			<li>（2）危害国家安全，泄露国家秘密；</li>
			<li>（3）颠覆国家政权，推翻社会主义制度，煽动分裂国家，破坏国家统一的；</li>
			<li>（4）损害国家荣誉和利益的；</li>
			<li>（5）宣扬恐怖主义、极端主义的；</li>
			<li>（6）宣扬民族仇恨、民族歧视，破坏民族团结的；</li>
			<li>（7）煽动地域歧视、地域仇恨的；</li>
			<li>（8）破坏国家宗教政策，宣扬邪教和迷信的；</li>
			<li>（9）编造、散布谣言、虚假信息，扰乱社会秩序、破坏社会稳定的；</li>
			<li>（10）散布、传播淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</li>
			<li>（11）危害网络安全、利用网络从事危害国家安全、荣誉和利益的；</li>
			<li>（12）侮辱或者诽谤他人，侵害他人合法权益的；</li>
			<li>（13）对他人进行暴力恐吓、威胁，实施人肉搜索的；</li>
			<li>（14）涉及他人隐私、个人信息或资料的；</li>
			<li>（15）散布污言秽语，损害社会公序良俗的；</li>
			<li>（16）侵犯他人隐私权、名誉权、肖像权、知识产权等合法权益内容的；</li>
			<li>（17）散布商业广告，或类似的商业招揽信息、过度营销信息及垃圾信息；</li>
			<li>（18）使用本网站常用语言文字以外的其他语言文字评论的；</li>
			<li>（19）与所评论的信息毫无关系的；</li>
			<li>（20）所发表的信息毫无意义的，或刻意使用字符组合以逃避技术审核的；</li>
			<li>（21）侵害未成年人合法权益或者损害未成年人身心健康的；</li>
			<li>（22）未获他人允许，偷拍、偷录他人，侵害他人合法权利的；</li>
			<li>（23）包含恐怖、暴力血腥、高危险性、危害表演者自身或他人身心健康内容的，包括但不限于以下情形：</li>
			<ul>
				<li>1）任何暴力和/或自残行为内容；</li>
				<li>2）任何威胁生命健康、利用刀具等危险器械表演的危及自身或他人人身及/或财产权利的内容；</li>
				<li>3）怂恿、诱导他人参与可能会造成人身伤害或导致死亡的危险或违法活动的内容。</li>
			</ul>
			<li>（24）其他违反法律法规、政策及公序良俗、干扰“Whistle”正常运营或侵犯其他用户或第三方合法权益内容的其他信息。</li>
		</ul>
		
		<h2>6、“Whistle”数据使用规范</h2>
		
		<p>6.1 未经公司书面许可，用户不得自行或授权、允许、协助任何第三人对本协议“Whistle”软件及相关服务中信息内容进行如下行为：</p>
		<ul>
			<li>(1) 复制、读取、采用“Whistle”软件及相关服务的信息内容，用于包括但不限于宣传、增加阅读量、浏览量等商业用途；</li>
			<li>(2) 擅自编辑、整理、编排“Whistle”软件及相关服务的信息内容后在Whistle软件及相关服务的源页面以外的渠道进行展示；</li>
			<li>(3) 采用包括但不限于特殊标识、特殊代码等任何形式的识别方法，自行或协助第三人对“Whistle”软件及相关服务的信息或内容产生流量、阅读量引导、转移、劫持等不利影响；</li>
			<li>(4) 其他非法获取“Whistle”软件及相关服务的信息内容的行为。</li>
		</ul>
	
		<p>6.2 经公司书面许可后，用户对“Whistle”软件及相关服务的信息和内容的分享、转发等行为，还应符合以下规范：</p>
		<ul>
			<li>(1) 对抓取、统计、获得的相关搜索热词、命中率、分类、搜索量、点击率、阅读量等相关数据，未经公司事先书面同意，不得将上述数据以任何方式公示、提供、泄露给任何第三人；</li>
			<li>(2) 不得对“Whistle”软件及相关服务的源网页进行任何形式的任何改动，包括但不限于“Whistle”软件及相关服务的首页链接入口，也不得对“Whistle”软件及相关服务的源页面的展示进行任何形式的遮挡、插入、弹窗等妨碍；</li>
			<li>(3) 应当采取安全、有效、严密的措施，防止“Whistle”软件及相关服务的信息内容被第三方通过包括但不限于“蜘蛛”（spider）程序等任何形式进行非法获取；</li>
			<li>(4) 不得把相关数据内容用于公司书面许可范围之外的目的，进行任何形式的销售和商业使用，或向第三方泄露、提供或允许第三方为任何方式的使用；</li>
			<li>(5) 用户向任何第三人分享、转发、复制“Whistle”软件及相关服务信息内容的行为，还应遵守公司为此制定的其他规范和标准，如社区自律公约及“Whistle”其他功能的相关协议。</li>
		</ul>
	
		<h2>7、违约处理</h2>
		
		<p>7.1 针对您违反本协议或其他服务条款的行为，公司有权独立判断并视情况采取预先警示、拒绝发布、立即停止传输信息、删除跟帖、音频、视频等内容、短期禁止发言、限制账号部分或者全部功能直至永久关闭账号等措施。公司有权公告处理结果，且有权根据实际情况决定是否恢复使用。对涉嫌违反法律法规、涉嫌违法犯罪的行为将保存有关记录，并依法向有关主管部门报告、配合有关主管部门调查。对已删除内容公司有权不予返还。</p>
		<p>7.2 因您违反本协议或其他服务条款规定，引起第三方投诉或诉讼索赔的，您应当自行承担全部法律责任。因您的违法或违约行为导致公司及其关联公司、控制公司、继承公司向任何第三方赔偿或遭受国家机关处罚的，您还应足额赔偿公司及其关联公司、控制公司、继承公司因此遭受的全部损失。</p>
		<p>7.3 公司尊重并保护法人、公民的知识产权、名誉权、姓名权、隐私权等合法权益。您保证，在使用“Whistle”软件及相关服务时上传的文字、视频、音频、链接等不侵犯任何第三方的知识产权、名誉权、姓名权、隐私权等权利及合法权益。否则，公司有权在收到权利方或者相关方通知的情况下移除该涉嫌侵权内容。针对第三方提出的全部权利主张，您应自行承担全部法律责任；如因您的侵权行为导致公司及其关联公司、控制公司、继承公司遭受损失的（包括但不限于经济、商誉等损失），您还应足额赔偿公司及其关联公司、控制公司、继承公司遭受的全部损失。</p>
		<h2>8、服务的变更、中断和终止</h2>
		<p>8.1 您理解并同意，公司提供的“Whistle”软件及相关服务是按照现有技术和条件所能达到的现状提供的。公司会尽最大努力向您提供服务，确保服务的连贯性和安全性。您理解，公司不能随时预见和防范法律、技术以及其他风险，包括但不限于不可抗力、病毒、木马、黑客攻击、系统不稳定、第三方服务瑕疵及其他各种安全问题的侵扰等原因可能导致的服务中断、数据丢失以及其他的损失和风险。</p>
		<p>8.2 您理解并同意，公司为了服务整体运营的需要，有权在公告通知后修改、中断、中止或终止“Whistle”软件及相关服务，而无须向用户负责或承担任何赔偿责任。</p>
		<h2>9、广告</h2>
		<p>9.1 您使用“Whistle”软件及相关服务过程中，充分理解并同意：本服务中可能包括公司针对个人或企业推出的信息、广告发布或品牌推广服务，您同意公司有权在“Whistle”软件及相关服务中展示“Whistle”软件及相关服务和/或第三方供应商、合作伙伴的商业广告、推广或信息（包括商业或非商业信息）。</p>
		<p>9.2 如您不同意该广告，您有权选择关闭该广告信息；“Whistle”推送通知服务的，您有权自行关闭该服务或可停止使用“Whistle”软件及相关服务。</p>
		<p>9.3 公司依照法律规定履行广告及推广相关义务，您应当自行判断该广告或推广信息的真实性和可靠性并为自己的判断行为负责。除法律法规明确规定外，您因该广告或推广信息进行的购买、交易或因前述内容遭受的损害或损失，用户应自行承担，公司不予承担责任。</p>
	
		<h2>10、知识产权</h2>
		<p>10.1 公司在“Whistle”软件及相关服务中提供的内容（包括但不限于软件、技术、程序、网页、文字、图片、图像、音频、视频、图表、版面设计、电子文档等）的知识产权属于公司所有。公司提供本服务时所依托的软件的著作权、专利权及其他知识产权均归公司所有。未经公司许可，任何人不得擅自使用（包括但不限于通过任何机器人、蜘蛛等程序或设备监视、复制、传播、展示、镜像、上载、下载“Whistle”软件及相关服务中的内容）。</p>
		<p>10.2 您理解并同意，在使用“Whistle”软件及相关服务时发布上传的文字、图片、视频、音频等均由您原创或已获合法授权（含转授权）。您通过“Whistle”上传、发布的任何内容的知识产权归属您或原始著作权人所有。</p>
		<p>10.3 您知悉、理解并同意您通过“Whistle”发布上传的内容（包括但不限文字，图片，视频，音频，视频及/或音频中包括的音乐作品、声音、对话等），授予公司及其关联公司、控制公司、继承公司一项全球范围内、免费、非独家、可再许可（通过多层次）的权利（包括但不限于复制权、翻译权、汇编权、信息网络传播权、改编权、制作衍生品等），使用范围包括但不限于在当前或其他网站、应用程序、产品或终端设备等。您在此确认并同意，上述权利的授予包括在与内容、“Whistle”、公司和/或公司品牌有关的任何推广、广告、营销和/或宣传中使用和以其他方式开发内容(全部或部分)的权利和许可。为避免疑惑，您同意，上述权利的授予包括使用、复制和展示您拥有或被许可使用并植入内容中的个人形象、肖像、姓名、商标、服务标志、品牌、名称、标识和公司标记(如有)以及任何其他品牌、营销或推广资产的权利和许可。</p>
		<p>10.4 您确认并同意授权公司以公司自己的名义或委托专业第三方对侵犯您上传发布的享有知识产权的内容进行代维权，维权形式包括但不限于：监测侵权行为、发送维权函、提起诉讼或仲裁、调解、和解等，公司有权对维权事宜做出决策并独立实施。</p>
		<p>10.5 公司为“Whistle”开发、运营提供技术支持，并对“Whistle”软件及相关服务的开发和运营等过程中产生的所有数据和信息等享有全部权利。</p>
		<p>10.6 请您在任何情况下都不要私自使用公司的包括但不限于“Whistle”等在内的任何商标、服务标记、商号、域名、网站名称或其他显著品牌特征等（以下统称为“标识”）。未经公司事先书面同意，您不得将本条款前述标识以单独或结合任何方式展示、使用或申请注册商标、进行域名注册等，也不得实施向他人明示或暗示有权展示、使用、或其他有权处理该些标识的行为。由于您违反本协议使用公司上述商标、标识等给公司或他人造成损失的，由您承担全部法律责任。</p>
		
		<h2>11、免责声明</h2>
		<p>11.1 您理解并同意，“Whistle”软件及相关服务可能会受多种因素的影响或干扰，公司不保证(包括但不限于)：</p>
		<p>11.1.1 公司完全适合用户的使用要求；</p>
		<p>11.1.2 公司不受干扰，及时、安全、可靠或不出现错误；用户经由公司取得的任何软件、服务或其他材料符合用户的期望；</p>
		<p>11.1.3 软件中任何错误都将能得到更正。</p>
		<p>11.2 对于涉嫌借款或其他涉财产的网络信息、账户密码、广告或推广等信息的，用户请谨慎对待并自行进行判断，基于前述原因您因此遭受的利润、商业信誉、资料损失或其他有形或无形损失，公司不承担任何直接、间接、附带、特别、衍生性或惩罚性的赔偿责任。</p>
		<p>11.3 用户理解并同意，在使用“Whistle”软件及相关服务过程中，可能遇到不可抗力等因素（不可抗力是指不能预见、不能克服并不能避免的客观事件），包括但不限于政府行为、自然灾害、网络原因、黑客攻击、战争或任何其它类似事件。出现不可抗力情况时，公司将努力在第一时间及时修复，但因不可抗力给用户造成了损失，用户同意公司不承担责任。</p>
		<p>11.4 公司依据本协议约定获得处理违法违规内容的权利，该权利不构成公司的义务或承诺，公司不能保证及时发现违法行为或进行相应处理。</p>
		<p>11.5 用户明确了解并同意：关于本协议服务，公司不提供任何种类的明示或暗示担保或条件，包括但不限于商业适售性、特定用途适用性等。您对本协议软件及相关服务的使用行为必须自行承担相应风险。</p>
		<p>11.6 用户明确了解并同意，本协议是在保障遵守国家法律法规、维护公序良俗，保护他人合法权益，公司在能力范围内尽最大的努力按照相关法律法规进行判断，但并不保证公司判断完全与司法机关、行政机关的判断一致，如因此产生的后果用户已经理解并同意自行承担。</p>
		
		<h2>12、关于单项服务的特殊约定</h2>
		<p>12.1 “Whistle”软件及相关服务中包含公司以各种合法方式获取的信息或信息内容链接，同时也包括公司及其关联公司合法运营的其他单项服务。这些服务在“Whistle”可能以单独板块形式存在。公司有权不时地增加、减少或改动这些特别板块的设置及服务。</p>
		<p>12.2 您可以在“Whistle”软件中开启和使用上述单项服务功能。某些单项服务可能需要您同时接受就该服务特别制订的协议或者其他约束您与该项服务提供者之间的规则。公司将以醒目的方式提供这些协议、规则供您查阅。一旦您开始使用上述服务，则视为您同时接受有关单项服务的相关协议、规则的约束。</p>
		<p>12.3 如您可能在“Whistle”软件中使用第三方提供的软件及相关服务时，除遵守本协议及“Whistle”软件中的其他相关规则外，还应遵守第三方的协议、相关规则。如因第三方软件及相关服务产生的争议、损失或损害，由您自行与第三方解决。</p>
		
		<h2>13、未成年人使用条款</h2>
		<p>13.1 若您是未满18周岁的未成年人，在使用“Whistle”软件及相关服务前，应在您的父母或其他监护人监护、指导下阅读并同意本协议。</p>
		<p>13.2 公司重视对未成年人个人信息的保护，未成年用户在填写个人信息时，请加强个人保护意识并谨慎对待，并在监护人指导时正确使用“Whistle”软件及相关服务。</p>
		<p>13.3 未成年用户理解如因您违反法律法规、本协议内容，则您及您的监护人应依照法律规定承担因此而导致的一切后果。</p>
		<p>13.4 未成年人用户特别提示</p>
		<p>13.4.1 青少年及使用“Whistle” 软件及相关服务应该在其监护人的监督指导下，在合理范围内正确学习使用网络，避免沉迷虚拟的网络空间，养成良好上网习惯。</p>
		<p>13.4.2 青少年用户必须遵守《全国青少年网络文明公约》：</p>
		<ul>
			<li>（1）要善于网上学习，不浏览不良信息；</li>
      <li>（2）要诚实友好交流，不侮辱欺诈他人；</li>
      <li>（3）要增强自护意识，不随意约会网友；</li>
      <li>（4）要维护网络安全，不破坏网络秩序；</li>
			<li>（5）要有益身心健康，不沉溺虚拟时空。</li>
		</ul>
		<p>13.4.3为更好的保护未成年人隐私权益，公司提醒用户慎重发布包含未成年人素材的内容，一经发布，即视为用户同意“Whistle”展示未成年人的信息、肖像、声音等，且允许“Whistle”依据本协议使用、处理该等与未成年人相关的内容。</p>
		
		<h2>14、其他</h2>
		<p>14.1本协议的成立、生效、履行、解释及争议的解决均应适用中华人民共和国大陆地区法律。倘若本协议之任何规定因与中华人民共和国大陆地区的法律抵触而无效，则这些条款将尽可能接近本协议原条文意旨重新解析，且本协议其它规定仍应具有完整的效力及效果。</p>
		<p>14.2本协议的签署地点为中华人民共和国北京市海淀区，若您与公司发生争议的，双方应尽量友好协商解决，协商不成，您同意应将争议提交至北京市海淀区人民法院管辖。</p>
		<p>14.3公司有权依据国家政策、技术条件、产品功能等变化需要而进行修改本协议，公司会将修改后的协议予以发布。前述内容一经正式发布，公司将以适当的方式（官方网站公布、系统通知等）提醒您更新的内容，以便您及时了解本协议的最新版本。修改后的内容将构成本协议不可分割的组成部分，您应同样遵守。您对修改后的协议有异议的，请立即停止登录、使用“Whistle”软件及相关服务，若您登录或继续使用“Whistle”软件及相关服务，视为认可修改后的协议。</p>
		<p>14.4 本协议中的标题仅为方便及阅读而设，并不影响本协议中任何规定的含义或解释。</p>
		<p>14.5您和公司均是独立的主体，在任何情况下本协议不构成公司对用户的任何形式的明示或暗示担保或条件，双方之间亦不构成代理、合伙、合营或雇佣关系。</p>
		<p>14.6本协议的版权为公司所有，公司在法律允许的范围内保留一切解释和修改的权利。</p>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				options: [
					{
						value: '中文',
						label: '中文'
					}, {
						value: 'English',
						label: 'English'
					}, {
						value: '한국인',
						label: '한국인'
					}, {
						value: '日本語',
						label: '日本語'
					}
				],
				value: '',
				language: ''
			}
		},
		created() {
			// url获取参数
			this.language = this.$route.query.language;
			if (this.language == 'zh_CN') {
				this.$router.push({
					path: '/UserServiceAgreementZh'
				})
			}
			if (this.language == 'en_US') {
				this.$router.push({
					path: '/UserServiceAgreementEn'
				})
			}
		},
		methods: {
			clickChange(value) {
				this.value = value;
				if (value == '中文') {
					this.$router.push({
						path: '/UserServiceAgreementZh'
					})
				}
				if (value == 'English') {
					this.$router.push({
						path: '/UserServiceAgreementEn'
					})
				}
				if (value == '한국인') {
					this.$router.push({
						path: '/UserServiceAgreementKo'
					})
				}
				if (value == '日本語') {
					this.$router.push({
						path: '/UserServiceAgreementJa'
					})
				}
			},
		}
	}
</script>

<style>
	.container {
		padding: 0.1rem 0.3rem;		
	}
	
	.lang {
		text-align: right;
		margin-bottom: 0.3rem;
	}

	h1,
	h2 {
		color: #333;
		font-size: 0.5rem;
	}

	h3 {
		margin-top: 20px;
		font-size: 0.4rem;
	}

	p {
		margin-bottom: 15px;
		font-size: 0.3rem;
	}
	
	li {
		font-size: 0.3rem;
	}
</style>